import React, { useCallback } from "react";
import classNames from "classnames";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import styles from "./styles.module.sass";

export function Button({
  isLoading = false,
  label,
  className,
  color = "primary",
  isDisabled = false,
  icon,
  isLink = false,
  onClick,
  children,
  ...props
}) {
  const { userConnected } = useSelector(state => state.currentUser);
  const { asPath } = useRouter();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }

    if (!isEmpty(userConnected)) {
      sendAnalytics(ANALYTIC_EVENTS.BUTTON_CLICKED, {
        pathname: asPath,
        label,
      });
    }
  }, [label, onClick, asPath, userConnected]);

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...props}
      className={classNames(
        { [styles.disabled]: isLoading },
        { [styles.disabled]: isDisabled },
        { "bg-none border-none text-indigo-500 py-0 px-0 hover:underline": isLink },
        {
          "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white shadow-sm":
            color === "primary" && !isLink,
        },
        { "bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white shadow-sm": color === "danger" && !isLink },
        {
          "bg-white hover:bg-gray-50 dark:hover:bg-gray-50 border dark:border-none  border-gray-300 focus:ring-indigo-500 text-gray-800 shadow-sm":
            color === "default" && !isLink,
        },
        "inline-flex items-center justify-center py-2 px-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2",
        className,
      )}
      onClick={handleClick}
    >
      {icon && !isLoading && icon}
      {isLoading && <ArrowPathIcon className={classNames("h-5 w-5", styles.loader)} aria-hidden="true" />}
      {!isLoading && label}
      {children && children}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.any,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLink: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
