import { fetchWithHeaders, getQueryParams, HTTP_METHODS, parseErrorResponse, responseData } from "@/utils/httpRequest";

export function fetchPropertyTypes(companyId, params) {
  return fetchWithHeaders(`/parameters/property-types?${getQueryParams(params)}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchPropertyType(companyId, path) {
  return fetchWithHeaders(`/property-types/${path.propertyTypeId}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function updatePropertyType(companyId, path, payload = {}) {
  return fetchWithHeaders(`/property-types/${path.propertyTypeId}`, {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function createPropertyType(companyId, payload = {}) {
  return fetchWithHeaders("/property-types", {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function deletePropertyType(companyId, path) {
  return fetchWithHeaders(`/property-types/${path.propertyTypeId}`, {
    method: HTTP_METHODS.DELETE,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
