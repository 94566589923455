import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Bugsnag from "@bugsnag/js";
import Router from "next/router";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareAnonymousHeaders } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import BORROWER_LOAN_ORIGINATION from "@/features/BorrowerLoanOrigination/events";
import { setOrganization } from "@/features/BorrowerLoanOrigination/store/slices/organizationDetailsSlice";

export const borrowerLoanOriginationService = createApi({
  reducerPath: "borrowerLoanOriginationService",
  tagTypes: ["BorrowerLoanOrigination"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareAnonymousHeaders,
  }),
  endpoints: builder => ({
    getOrganizationDetails: builder.query({
      query: id => `organizations/${id}/loan-origination`,
      providesTags: (result, error, id) => [{ type: "BorrowerLoanOrigination", id }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setOrganization(data));
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    sendApplication: builder.mutation({
      query: ({ organizationId: id, ...body }) => ({
        url: `organizations/${id}/loan-origination`,
        method: "POST",
        body,
      }),
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(BORROWER_LOAN_ORIGINATION.APPLICATION_SUBMITTED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Your application has been submitted.",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );

          Router.push(`/organizations/${organizationId}/loan-application/submitted`);
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
  }),
});

export const { useGetOrganizationDetailsQuery, useSendApplicationMutation } = borrowerLoanOriginationService;
