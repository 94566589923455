import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Bugsnag from "@bugsnag/js";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics, sendCollectionToAnalytics } from "@/utils/analytics";
import { requestCloseSlideOver } from "@/redux/actions/slide_over";
import { borrowerNeedsListService } from "@/redux/services/borrowerNeedsListService";
import { borrowerDashboardService } from "@/redux/services/borrowerDashboard";
import { borrowerNeedsListFileService } from "@/redux/services/borrowerNeedsListFilesService";
import { baseUrl, onPrepareHeadersBlob } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import { contactFileService } from "@/redux/services/contactFileService";
import { lenderDashboardService } from "@/redux/services/lenderDashboard";
import { contactService } from "@/redux/services/contactService";
import { lenderCallLogService } from "@/redux/services/lenderCallLogService";

export const fileProcessorService = createApi({
  reducerPath: "fileProcessorApi",
  tagTypes: ["BorrowerNeedsListFile", "ContactFile", "ContactFileFromLenderDashboard"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeadersBlob,
  }),
  endpoints: builder => ({
    addBorrowerNeedsListFile: builder.mutation({
      query: ({ loanId, borrowerId, needListId, formData: body }) => ({
        url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_CREATED, data.files);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File(s) added to the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(borrowerNeedsListService.util.invalidateTags(["BorrowerNeedsList"]));
          dispatch(borrowerNeedsListFileService.util.invalidateTags(["BorrowerNeedsListFile"]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    addBorrowerNeedsListFileFromDashboard: builder.mutation({
      query: ({ token, needListId, formData: body }) => ({
        url: `borrowers/${token}/needs-list/${needListId}/files`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_CREATED_BY_BORROWER, data.files);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File(s) added to the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(borrowerDashboardService.util.invalidateTags([{ type: "BorrowerNeedsList", id: "LIST" }]));
          dispatch(borrowerDashboardService.util.invalidateTags([{ type: "BorrowerNeedsListFile", id: "LIST" }]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    addContactFile: builder.mutation({
      query: ({ contactId, formData }) => ({
        url: `contacts/${contactId}/files`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.CONTACT_FILE_CREATED, data.files);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File(s) added to the lender's file!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(contactFileService.util.invalidateTags([{ type: "ContactFile", id: "LIST" }]));
          dispatch(contactService.util.invalidateTags([{ type: "Contact", id: "LIST" }]));
          dispatch(lenderCallLogService.util.invalidateTags([{ type: "LenderCallLog", id: "LIST" }]));
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    addContactFileFromLenderDashboard: builder.mutation({
      query: ({ token, formData: body }) => ({
        url: `lenders/${token}/files`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "ContactFileFromLenderDashboard", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_CREATED_BY_BORROWER, data.files);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File(s) added to the lender's file!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(lenderDashboardService.util.invalidateTags([{ type: "ContactFile", id: "LIST" }]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    downloadLoanSummaryNeedsListFile: builder.mutation({
      query: ({ token, borrowerId, needsListId, needsListFileId }) => ({
        url: `lenders/${token}/borrowers/${borrowerId}/needs-list/${needsListId}/files/${needsListFileId}/physical`,
        method: "GET",
      }),
      invalidatesTags: (result, error, { token }) => [{ type: "LenderSummaryNeedsList", token }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_DOWNLOAD, data);
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useAddBorrowerNeedsListFileMutation,
  useAddBorrowerNeedsListFileFromDashboardMutation,
  useAddContactFileMutation,
  useAddContactFileFromLenderDashboardMutation,
  useDownloadLoanSummaryNeedsListFileMutation,
} = fileProcessorService;
