import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Bugsnag from "@bugsnag/js";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "@/redux/actions/notification";
import { setProcessingState } from "@/redux/reducers/modalConfirmation";
import { requestCloseModal } from "@/redux/actions/modal";
import { contactService } from "@/redux/services/contactService";
import { lenderCallLogService } from "@/redux/services/lenderCallLogService";

export const defaultContactFileFilters = {
  page: 1,
  size: 50,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const contactFileService = createApi({
  reducerPath: "contactFileApi",
  tagTypes: ["ContactFile"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getContactFiles: builder.query({
      query: contactId => `contacts/${contactId}/files?${getQueryParams(defaultContactFileFilters)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ contactFileId }) => ({ type: "ContactFile", id: contactFileId })),
              { type: "ContactFile", id: "LIST" },
            ]
          : [{ type: "ContactFile", id: "LIST" }],
    }),
    deleteContactFile: builder.mutation({
      query({ contactId, contactFileId }) {
        return {
          url: `contacts/${contactId}/files/${contactFileId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_FILE_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File removed from the contact's file list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
          dispatch(contactService.util.invalidateTags([{ type: "Contact", id: "LIST" }]));
          dispatch(lenderCallLogService.util.invalidateTags([{ type: "LenderCallLog", id: "LIST" }]));
        } catch (err) {
          Bugsnag.notify(err);
          dispatch(setProcessingState(false));
        }
      },
    }),
    previewContactFile: builder.mutation({
      query({ contactId, contactFileId }) {
        return {
          url: `contacts/${contactId}/files/${contactFileId}/preview`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(queryArgs, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.target = "_blank";
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_FILE_PREVIEW, data);
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    downloadContactFile: builder.mutation({
      query({ contactId, contactFileId }) {
        return {
          url: `contacts/${contactId}/files/${contactFileId}/physical`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_FILE_DOWNLOAD, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been downloaded!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
  }),
});

export const {
  useGetContactFilesQuery,
  useDeleteContactFileMutation,
  usePreviewContactFileMutation,
  useDownloadContactFileMutation,
} = contactFileService;
