import React from "react";
import { Provider } from "react-redux";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import PropTypes from "prop-types";
import { NextAdapter } from "next-query-params";
import { QueryParamProvider } from "use-query-params";
import { MsalProvider } from "@azure/msal-react";
import Bugsnag from "@bugsnag/js";

import { initializeStore } from "@/redux/store";
import { pagePropsShape } from "@/utils/propTypeShapes";
import "../styles/globals.sass";
import { NotificationBus } from "@/components/notification/notification_bus";
import { msalInstance } from "@/integrations/microsoft_graph";
import initializeIntegrations from "@/integrations/index";
import { loadConfiguration } from "@/utils/loadConfiguration";
import { Modal } from "@/components/modals/modal";
import { SlideOver } from "@/components/slide_over/slide_over";

initializeIntegrations();

const store = initializeStore();
loadConfiguration(store).catch(error => Bugsnag.notify(error));

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function App({ Component, pageProps }) {
  const { user } = pageProps;

  return (
    <ErrorBoundary>
      <MsalProvider instance={msalInstance}>
        <UserProvider user={user}>
          <Provider store={store}>
            <QueryParamProvider adapter={NextAdapter}>
              <NotificationBus>
                <Component {...pageProps} />
                <SlideOver>
                  <Modal />
                </SlideOver>
              </NotificationBus>
            </QueryParamProvider>
          </Provider>
        </UserProvider>
      </MsalProvider>
    </ErrorBoundary>
  );
}

export default App;

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: pagePropsShape,
};
