import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const borrowerProfileSlice = createSlice({
  name: "borrowerProfile",
  initialState,
  reducers: {
    setBorrowerProfileData(state, action) {
      return { ...state, data: action.payload };
    },
  },
});

export const { setBorrowerProfileData } = borrowerProfileSlice.actions;

export default borrowerProfileSlice.reducer;
