import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, ANALYTICS_USER_TYPES, identifyUser, sendAnalytics } from "@/utils/analytics";
import { setBorrowerProfileData } from "@/redux/reducers/borrowerProfileStore";

export const defaultBorrowerNeedsListFileParameters = {
  page: 1,
  size: 5,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const borrowerDashboardService = createApi({
  reducerPath: "borrowerDashboardApi",
  tagTypes: ["BorrowerLoan", "BorrowerNeedsList", "BorrowerNeedsListFile"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getBorrowerLoan: builder.query({
      query: token => `borrowers/${token}/loan`,
      providesTags: (result, error, { token }) => [{ type: "BorrowerLoan", id: token }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          identifyUser({
            userId: data?.borrower.borrowerId,
            userType: ANALYTICS_USER_TYPES.BORROWER,
            email: data?.borrower.email,
            firstName: data?.borrower.firstName,
            lastName: data?.borrower.lastName,
            fullName: `${data?.borrower.firstName} ${data?.borrower.lastName}`,
            companyId: data?.borrower.companyId,
            companyName: data?.borrower.companyName,
          });

          dispatch(setBorrowerProfileData(data?.borrower));
        } catch (err) {
          /* empty */
        }
      },
    }),
    getBorrowerNeedsList: builder.query({
      query: ({ token, ...params }) => `borrowers/${token}/needs-list?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ needListId }) => ({ type: "BorrowerNeedsList", needListId })),
              { type: "BorrowerNeedsList", id: "LIST" },
            ]
          : [{ type: "BorrowerNeedsList", id: "LIST" }],
      transformResponse(baseQueryReturnValue) {
        return {
          ...baseQueryReturnValue,
          items: baseQueryReturnValue.items.map(item => ({
            ...item,
            lastNotificationSentAt: item.lastNotificationSentAt ? new Date(item.lastNotificationSentAt) : null,
          })),
        };
      },
    }),
    getBorrowerNeedsListFiles: builder.query({
      query: ({ token, needListId, ...params }) =>
        `borrowers/${token}/needs-list/${needListId}/files?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ needListFileId }) => ({ type: "BorrowerNeedsListFile", needListFileId })),
              { type: "BorrowerNeedsListFile", id: "LIST" },
            ]
          : [{ type: "BorrowerNeedsListFile", id: "LIST" }],
    }),
    deleteBorrowerNeedsListFile: builder.mutation({
      query({ token, needListId, needListFileId }) {
        return {
          url: `borrowers/${token}/needs-list/${needListId}/files/${needListFileId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        { type: "BorrowerNeedsList", id: "LIST" },
        { type: "BorrowerNeedsListFile", id: "LIST" },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_DELETED_BY_BORROWER, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been removed!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    previewBorrowerNeedsListFile: builder.mutation({
      query({ token, needListId, needListFileId }) {
        return {
          url: `borrowers/${token}/needs-list/${needListId}/files/${needListFileId}/preview`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.target = "_blank";
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_PREVIEW, data);
        } catch (err) {
          /* empty */
        }
      },
    }),
    downloadBorrowerNeedsListFile: builder.mutation({
      query({ token, needListId, needListFileId }) {
        return {
          url: `borrowers/${token}/needs-list/${needListId}/files/${needListFileId}/physical`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_DOWNLOAD, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been downloaded!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetBorrowerLoanQuery,
  useGetBorrowerNeedsListQuery,
  useGetBorrowerNeedsListFilesQuery,
  useDeleteBorrowerNeedsListFileMutation,
  usePreviewBorrowerNeedsListFileMutation,
  useDownloadBorrowerNeedsListFileMutation,
} = borrowerDashboardService;
