import React, { Fragment, useCallback, useMemo, useRef } from "react";
import { Dialog, DialogBackdrop, DialogPanel, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { requestCloseModal } from "@/redux/actions/modal";
import { MODAL_SIZES, MODAL_COLLECTION } from "@/components/modals/util";
import styles from "./styles.module.sass";

export function Modal() {
  const { isOpen, fragment, options } = useSelector(state => state.modal);
  const dispatch = useDispatch();
  const focus = useRef();

  const handleClose = useCallback(() => {
    dispatch(requestCloseModal());
  }, [dispatch]);

  const ModalInstance = useMemo(() => {
    const Instance = MODAL_COLLECTION[fragment];

    if (!Instance) return null;

    return <Instance handleClose={handleClose} options={options} />;
  }, [fragment, handleClose, options]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames("fixed overflow-hidden z-10 inset-0 origin-top overflow-y-auto", styles.zIndex)}
        initialFocus={focus}
        onClose={handleClose}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="h-full items-end pt-12 overflow-y-auto justify-center px-4 text-center sm:block">
          <DialogPanel transition>
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              ref={focus}
              className="hidden align-middle sm:inline-block sm:align-top sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={classNames(
                  "w-full inline-block rounded-md bg-white dark:bg-slate-900 text-left shadow-2xl transform transition-all sm:my-8",
                  { "max-w-2xl": options.size === MODAL_SIZES.SM },
                  { "max-w-4xl": options.size === MODAL_SIZES.MD },
                  { "max-w-5xl": options.size === MODAL_SIZES.LG },
                )}
              >
                {ModalInstance}
              </div>
            </Transition.Child>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
