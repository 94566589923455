import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "ORGANIZATION_DETAILS";

const initialState = {
  organization: {},
};

const organizationDetailsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOrganization(state, action) {
      return {
        ...state,
        organization: action.payload,
      };
    },
  },
});

export const { setOrganization } = organizationDetailsSlice.actions;
export default organizationDetailsSlice.reducer;
