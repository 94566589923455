import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Bugsnag from "@bugsnag/js";
import isEmpty from "lodash/isEmpty";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";
import { ANALYTIC_EVENTS, ANALYTICS_USER_TYPES, identifyUser, sendAnalytics } from "@/utils/analytics";
import { showNotification } from "@/redux/actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { requestCloseModal } from "@/redux/actions/modal";
import { getQueryParams } from "@/utils/httpRequest";
import {
  clearLenderCriteriaParameterOnProcessingState,
  setLenderCriteriaParameterOnProcessingState,
} from "@/redux/reducers/lender_criteria_parameter";
import { setProcessingState } from "@/redux/reducers/modalConfirmation";
import { defaultContactFileFilters } from "@/redux/services/contactFileService";
import { setLenderProfileData } from "@/redux/reducers/lender_profile";

export const lenderDashboardService = createApi({
  reducerPath: "lenderDashboardApi",
  tagTypes: ["LenderProfile", "LenderProgram", "LenderProgramParameter", "ContactFile"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderProfile: builder.query({
      query: token => `lenders/${token}/profile`,
      providesTags: (result, error, { token }) => [{ type: "LenderProfile", id: token }],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const contact = await queryFulfilled;

          const { data } = getState().lenderProfileStore;

          identifyUser({
            userId: contact.data.contactId,
            userType: ANALYTICS_USER_TYPES.LENDER,
            email: contact.data.email,
            firstName: contact.data.firstName,
            lastName: contact.data.lastName,
            fullName: `${contact.data.firstName} ${contact.lastName}`,
            companyId: contact.data.companyId,
            companyName: contact.data.companyName,
          });

          if (isEmpty(data)) dispatch(setLenderProfileData(contact.data));
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    updateLenderProfile: builder.mutation({
      query: ({ token, ...body }) => ({
        url: `lenders/${token}/profile`,
        method: "PUT",
        body,
      }),
      invalidatesTags: () => ["LenderProfile"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_PROFILE_UPDATED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Profile information has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    getLenderPrograms: builder.query({
      query: id => `lenders/${id}/programs`,
      providesTags: () => [{ type: "LenderProgram", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const {
            data: { contact },
          } = await queryFulfilled;

          const { data } = getState().lenderProfileStore;

          identifyUser({
            userId: contact.contactId,
            userType: ANALYTICS_USER_TYPES.LENDER,
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            fullName: `${contact.firstName} ${contact.lastName}`,
            companyId: contact.companyId,
            companyName: contact.companyName,
          });

          if (isEmpty(data)) dispatch(setLenderProfileData(contact));
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    getLenderProgram: builder.query({
      query: ({ token, lenderCriteriaId }) => `lenders/${token}/programs/${lenderCriteriaId}`,
      providesTags: ({ lenderCriteriaId }) => [{ type: "LenderProgram", id: lenderCriteriaId }],
    }),
    addLenderProgram: builder.mutation({
      query: ({ token, ...body }) => ({
        url: `lenders/${token}/programs`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "LenderProgram", id: "LIST" },
        { type: "LenderProfile", id: arg.token },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_LENDING_PROGRAM_CREATED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Program has been added!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    updateLenderProgram: builder.mutation({
      query: ({ token, lenderCriteriaId, ...body }) => ({
        url: `lenders/${token}/programs/${lenderCriteriaId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ({ lenderCriteriaId }, error, arg) => [
        { type: "LenderProgram", id: "LIST" },
        { type: "LenderProgram", id: lenderCriteriaId },
        { type: "LenderProfile", id: arg.token },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_LENDING_PROGRAM_UPDATED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Program has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    deleteLenderProgram: builder.mutation({
      query: ({ token, lenderCriteriaId }) => ({
        url: `lenders/${token}/programs/${lenderCriteriaId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "LenderProgram", id: "LIST" },
        { type: "LenderProfile", id: arg.token },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_LENDING_PROGRAM_DELETED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Program has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );

          dispatch(requestCloseModal());
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    getLenderProgramParameters: builder.query({
      query: ({ token, lenderCriteriaId, parameterType, ...params }) =>
        `lenders/${token}/programs/${lenderCriteriaId}/parameter-types/${parameterType}/parameters?${getQueryParams(
          params,
        )}`,
      transformResponse: items => {
        if (isEmpty(items)) {
          return { items: 0, totalItems: 0, totalActiveItems: 0 };
        }

        const { totalRows, totalActiveRows } = items[0];
        return { items, totalItems: totalRows, totalActiveItems: totalActiveRows };
      },
      providesTags: (result, resultType, { parameterType }) => [{ type: "LenderProgramParameter", id: parameterType }],
    }),
    addLenderProgramParameter: builder.mutation({
      query: ({ token, lenderCriteriaId, parameterType, parameterId }) => ({
        url: `lenders/${token}/programs/${lenderCriteriaId}/parameter-types/${parameterType}/parameters/${parameterId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { parameterType, token }) => [
        { type: "LenderProgramParameter", id: parameterType },
        { type: "LenderProfile", id: token },
      ],
      async onQueryStarted({ parameterId }, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLenderCriteriaParameterOnProcessingState({ parameterId }));

          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_LENDING_PROGRAM_PARAMETER_CREATED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Parameter has been added to the program!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );

          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
        } catch (err) {
          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
          Bugsnag.notify(err);
        }
      },
    }),
    addLenderProgramParameters: builder.mutation({
      query: ({ token, lenderCriteriaId, parameterType, parentId }) => ({
        url: `lenders/${token}/programs/${lenderCriteriaId}/parameter-types/${parameterType}/parents${
          parentId ? `/${parentId}` : ""
        }`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { parameterType, token }) => [
        { type: "LenderProgramParameter", id: parameterType },
        { type: "LenderProfile", id: token },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_LENDING_PROGRAM_PARAMETER_CREATED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Parameter has been added to the program!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    deleteLenderProgramParameter: builder.mutation({
      query: ({ token, lenderCriteriaId, parameterType, parameterId }) => ({
        url: `lenders/${token}/programs/${lenderCriteriaId}/parameter-types/${parameterType}/parameters/${parameterId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { parameterType, token }) => [
        { type: "LenderProgramParameter", id: parameterType },
        { type: "LenderProfile", id: token },
      ],
      async onQueryStarted({ parameterId }, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLenderCriteriaParameterOnProcessingState({ parameterId }));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_LENDING_PROGRAM_PARAMETER_DELETED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Parameter has been removed from the program!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
        } catch (err) {
          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
          Bugsnag.notify(err);
        }
      },
    }),
    deleteLenderProgramParameters: builder.mutation({
      query: ({ token, lenderCriteriaId, parameterType, parentId }) => ({
        url: `lenders/${token}/programs/${lenderCriteriaId}/parameter-types/${parameterType}/parents${
          parentId ? `/${parentId}` : ""
        }`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { parameterType, token }) => [
        { type: "LenderProgramParameter", id: parameterType },
        { type: "LenderProfile", id: token },
      ],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // sendAnalytics(ANALYTIC_EVENTS.LENDER_DASHBOARD_LENDING_PROGRAM_PARAMETER_DELETED, data);

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "All parameters have been removed from the program!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    getAttachments: builder.query({
      query: token => `lenders/${token}/files?${getQueryParams(defaultContactFileFilters)}`,
      providesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const {
            data: { contact },
          } = await queryFulfilled;

          const { data } = getState().lenderProfileStore;

          identifyUser({
            userId: contact.contactId,
            userType: ANALYTICS_USER_TYPES.LENDER,
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            fullName: `${contact.firstName} ${contact.lastName}`,
            companyId: contact.companyId,
            companyName: contact.companyName,
          });

          if (isEmpty(data)) dispatch(setLenderProfileData(contact));
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    deleteAttachment: builder.mutation({
      query({ token, contactFileId }) {
        return {
          url: `lenders/${token}/files/${contactFileId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_FILE_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File removed from the contact's file list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
        } catch (err) {
          Bugsnag.notify(err);
          dispatch(setProcessingState(false));
        }
      },
    }),
    previewAttachment: builder.mutation({
      query({ token, contactFileId }) {
        return {
          url: `lenders/${token}/files/${contactFileId}/preview`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(queryArgs, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.target = "_blank";
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_FILE_PREVIEW, data);
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    downloadAttachment: builder.mutation({
      query({ token, contactFileId }) {
        return {
          url: `lenders/${token}/files/${contactFileId}/physical`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "ContactFile", id: "LIST" }],
      async onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_FILE_DOWNLOAD, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been downloaded!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
  }),
});

export const {
  useGetLenderProfileQuery,
  useUpdateLenderProfileMutation,
  useGetLenderProgramsQuery,
  useGetLenderProgramQuery,
  useAddLenderProgramMutation,
  useUpdateLenderProgramMutation,
  useDeleteLenderProgramMutation,
  useGetLenderProgramParametersQuery,
  useAddLenderProgramParameterMutation,
  useAddLenderProgramParametersMutation,
  useDeleteLenderProgramParameterMutation,
  useDeleteLenderProgramParametersMutation,
  useGetAttachmentsQuery,
  useDeleteAttachmentMutation,
  usePreviewAttachmentMutation,
  useDownloadAttachmentMutation,
} = lenderDashboardService;
