import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Bugsnag from "@bugsnag/js";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { getQueryParams } from "@/utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { lenderCriteriaService } from "./lenderCriteriaService";
import { contactService } from "./contactService";
import { loanService } from "./loanService";
import {
  clearLenderCriteriaParameterOnProcessingState,
  setLenderCriteriaParameterOnProcessingState,
} from "../reducers/lender_criteria_parameter";
import { lenderCallLogService } from "./lenderCallLogService";

export const lenderCriteriaParameterService = createApi({
  reducerPath: "lenderCriteriaParameterApi",
  tagTypes: ["LenderCriteriaParameters"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderCriteriaParameter: builder.query({
      query: ({ lenderId, lenderCriteriaId, parameterType, ...params }) =>
        `lenders/${lenderId}/criteria/${lenderCriteriaId}/parameter-types/${parameterType}/parameters?${getQueryParams(
          params,
        )}`,
      transformResponse: items => {
        if (isEmpty(items)) {
          return { items: 0, totalItems: 0, totalActiveItems: 0 };
        }

        const { totalRows, totalActiveRows } = items[0];
        return { items, totalItems: totalRows, totalActiveItems: totalActiveRows };
      },
      providesTags: (result, resultType, { parameterType }) => [
        { type: "LenderCriteriaParameters", id: parameterType },
      ],
    }),
    addLenderCriteriaParameter: builder.mutation({
      query: ({ lenderId, lenderCriteriaId, parameterType, parameterId }) => ({
        url: `lenders/${lenderId}/criteria/${lenderCriteriaId}/parameter-types/${parameterType}/parameters/${parameterId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { parameterType }) => [{ type: "LenderCriteriaParameters", id: parameterType }],
      async onQueryStarted({ parameterId }, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLenderCriteriaParameterOnProcessingState({ parameterId }));

          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_CRITERIA_PARAMETER_CREATED, data);

          dispatch(lenderCriteriaService.util.invalidateTags([{ type: "LenderCriteria", id: "LIST" }]));
          dispatch(contactService.util.invalidateTags(["Contact"]));
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Parameter has been added to the criteria!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );

          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
        } catch (err) {
          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
        }
      },
    }),
    addLenderCriteriaParameters: builder.mutation({
      query: ({ lenderId, lenderCriteriaId, parameterType, parentId }) => ({
        url: `lenders/${lenderId}/criteria/${lenderCriteriaId}/parameter-types/${parameterType}/parents${
          parentId ? `/${parentId}` : ""
        }`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { parameterType }) => [{ type: "LenderCriteriaParameters", id: parameterType }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // sendAnalytics(ANALYTIC_EVENTS.LENDER_CRITERIA_PARAMETER_CREATED, data);

          dispatch(lenderCriteriaService.util.invalidateTags([{ type: "LenderCriteria", id: "LIST" }]));
          dispatch(contactService.util.invalidateTags(["Contact"]));
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Parameter has been added to the criteria!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
    deleteLenderCriteriaParameter: builder.mutation({
      query: ({ lenderId, lenderCriteriaId, parameterType, parameterId }) => ({
        url: `lenders/${lenderId}/criteria/${lenderCriteriaId}/parameter-types/${parameterType}/parameters/${parameterId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { parameterType }) => [{ type: "LenderCriteriaParameters", id: parameterType }],
      async onQueryStarted({ parameterId }, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLenderCriteriaParameterOnProcessingState({ parameterId }));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_CRITERIA_PARAMETER_DELETED, data);

          dispatch(lenderCriteriaService.util.invalidateTags([{ type: "LenderCriteria", id: "LIST" }]));
          dispatch(contactService.util.invalidateTags(["Contact"]));
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Parameter has been removed from the criteria!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
        } catch (err) {
          dispatch(clearLenderCriteriaParameterOnProcessingState({ parameterId }));
        }
      },
    }),
    deleteLenderCriteriaParameters: builder.mutation({
      query: ({ lenderId, lenderCriteriaId, parameterType, parentId }) => ({
        url: `lenders/${lenderId}/criteria/${lenderCriteriaId}/parameter-types/${parameterType}/parents${
          parentId ? `/${parentId}` : ""
        }`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { parameterType }) => [{ type: "LenderCriteriaParameters", id: parameterType }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // sendAnalytics(ANALYTIC_EVENTS.LENDER_CRITERIA_PARAMETERS_DELETED, data);

          dispatch(lenderCriteriaService.util.invalidateTags([{ type: "LenderCriteria", id: "LIST" }]));
          dispatch(contactService.util.invalidateTags(["Contact"]));
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "All parameters have been removed from the criteria!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
  }),
});

export const {
  useGetLenderCriteriaParameterQuery,
  useAddLenderCriteriaParameterMutation,
  useAddLenderCriteriaParametersMutation,
  useDeleteLenderCriteriaParameterMutation,
  useDeleteLenderCriteriaParametersMutation,
} = lenderCriteriaParameterService;
