import { fetchWithHeaders, getQueryParams, HTTP_METHODS, parseErrorResponse, responseData } from "@/utils/httpRequest";

export function fetchPropertyUses(companyId, params) {
  return fetchWithHeaders(`/parameters/property-uses?${getQueryParams(params)}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchPropertyUse(companyId, path) {
  return fetchWithHeaders(`/property-uses/${path.propertyUseId}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function updatePropertyUse(companyId, path, payload = {}) {
  return fetchWithHeaders(`/property-uses/${path.propertyUseId}`, {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function createPropertyUse(companyId, payload = {}) {
  return fetchWithHeaders("/property-uses", {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function deletePropertyUse(companyId, path) {
  return fetchWithHeaders(`/property-uses/${path.propertyUseId}`, {
    method: HTTP_METHODS.DELETE,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
