import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import isEmpty from "lodash/isEmpty";
import Bugsnag from "@bugsnag/js";
import { ANALYTICS_USER_TYPES, identifyUser } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { setLenderProfileData } from "@/redux/reducers/lender_profile";

export const lenderSummaryService = createApi({
  reducerPath: "lenderSummaryApi",
  tagTypes: ["LenderSummary"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderSummary: builder.query({
      query: token => `lenders/${token}/summaries`,
      providesTags: (result, error, id) => [{ type: "LenderSummary", id }],
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = getState().lenderProfileStore;

          const {
            data: { lenderLoanSummary: contact },
          } = await queryFulfilled;

          identifyUser({
            userId: contact.lenderId,
            userType: ANALYTICS_USER_TYPES.LENDER,
            email: contact.lenderEmail,
            firstName: contact.lenderFirstName,
            lastName: contact.lenderLastName,
            fullName: `${contact.lenderFirstName} ${contact.lenderLastName}`,
            companyId: contact.companyId,
            companyName: contact.companyName,
          });

          if (isEmpty(data)) dispatch(setLenderProfileData({ firstName: contact.lenderFirstName }));
        } catch (err) {
          Bugsnag.notify(err);
        }
      },
    }),
  }),
});

export const { useGetLenderSummaryQuery } = lenderSummaryService;
