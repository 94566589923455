import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const lenderProfileSlice = createSlice({
  name: "lenderProfile",
  initialState,
  reducers: {
    setLenderProfileData(state, action) {
      return { ...state, data: action.payload };
    },
  },
});

export const { setLenderProfileData } = lenderProfileSlice.actions;

export default lenderProfileSlice.reducer;
