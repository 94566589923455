import { fetchWithHeaders, getQueryParams, HTTP_METHODS, parseErrorResponse, responseData } from "@/utils/httpRequest";

export function fetchLenderCallLogStatuses(companyId, params) {
  return fetchWithHeaders(`/parameters/lender-call-log-statuses?${getQueryParams(params)}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchLenderCallLogStatus(companyId, path) {
  return fetchWithHeaders(`/lender-call-log-statuses/${path.lenderCallLogStatusId}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function updateLenderCallLogStatus(companyId, path, payload = {}) {
  return fetchWithHeaders(`/lender-call-log-statuses/${path.lenderCallLogStatusId}`, {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function createLenderCallLogStatus(companyId, opts = {}) {
  return fetchWithHeaders("/lender-call-log-statuses", {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(opts),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function deleteLenderCallLogStatus(companyId, path) {
  return fetchWithHeaders(`/lender-call-log-statuses/${path.lenderCallLogStatusId}`, {
    method: HTTP_METHODS.DELETE,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
