import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import {
  clearLenderOfferFileOnProcessingState,
  setLenderOfferFileOnProcessingState,
} from "../reducers/lender_offer_file";

export const defaultLenderOfferFileFilters = {
  page: 1,
  size: 50,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const lenderOfferFileService = createApi({
  reducerPath: "lenderOfferFileApi",
  tagTypes: ["LenderOfferFile"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderOfferFiles: builder.query({
      query: ({ loanId, lenderId, lenderOfferId }) =>
        `loans/${loanId}/lenders/${lenderId}/offers/${lenderOfferId}/files?${getQueryParams(
          defaultLenderOfferFileFilters,
        )}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ lenderOfferFileId }) => ({ type: "LenderOfferFile", lenderOfferFileId })),
              { type: "LenderOfferFile", id: "LIST" },
            ]
          : [{ type: "LenderOfferFile", id: "LIST" }],
    }),
    deleteLenderOfferFile: builder.mutation({
      query({ loanId, lenderId, lenderOfferId, lenderOfferFileId }) {
        return {
          url: `loans/${loanId}/lenders/${lenderId}/offers/${lenderOfferId}/files/${lenderOfferFileId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "LenderOfferFile", id: "LIST" }],
      async onQueryStarted({ lenderOfferFileId: id }, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLenderOfferFileOnProcessingState({ fileId: id }));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_OFFER_FILE_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File removed from the lender's offer list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(clearLenderOfferFileOnProcessingState({ fileId: id }));
        } catch (err) {
          dispatch(clearLenderOfferFileOnProcessingState({ fileId: id }));
        }
      },
    }),
    previewLenderOfferFile: builder.mutation({
      query({ loanId, lenderId, lenderOfferId, lenderOfferFileId }) {
        return {
          url: `loans/${loanId}/lenders/${lenderId}/offers/${lenderOfferId}/files/${lenderOfferFileId}/preview`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "LenderOfferFile", id: "LIST" }],
      async onQueryStarted({ lenderOfferFileId: id }, { dispatch, queryFulfilled }) {
        try {
          dispatch(setLenderOfferFileOnProcessingState({ fileId: id }));
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.target = "_blank";
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.LENDER_OFFER_FILE_PREVIEW, data);
          dispatch(clearLenderOfferFileOnProcessingState({ fileId: id }));
        } catch (err) {
          dispatch(clearLenderOfferFileOnProcessingState({ fileId: id }));
        }
      },
    }),
    downloadLenderOfferFile: builder.mutation({
      query({ loanId, lenderId, lenderOfferId, lenderOfferFileId }) {
        return {
          url: `loans/${loanId}/lenders/${lenderId}/offers/${lenderOfferId}/files/${lenderOfferFileId}/download`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "LenderOfferFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.LENDER_OFFER_FILE_DOWNLOAD, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been downloaded!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetLenderOfferFilesQuery,
  useDeleteLenderOfferFileMutation,
  usePreviewLenderOfferFileMutation,
  useDownloadLenderOfferFileMutation,
} = lenderOfferFileService;
